* {
  margin: 0;
  padding: 0;
  line-height: normal;
  font-family: Roboto;
}

img {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -o-user-drag: none;
  user-select: none;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100dvh;
  overflow-x: hidden;
  background-color: #fafafa;
  min-width: 100dvw;
}

#root {
  display: flex;
  flex-direction: column;

  max-width: 100%;
  min-height: 100dvh;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}
